import React, { useEffect } from 'react';
import { CustomDialog, Scanner } from 'react-component-utils';
import { IScannerRef } from '../../../models/common';

interface DialogScanQrENVDProps {
    open: boolean;
    close: () => void;
    onScanQRResult: (decodedText: string) => void;
}

/**
 * DialogScanQrENVD component is used to render the dialog component to scan QR code
 */
const DialogScanQrENVD = ({ open, close, onScanQRResult }: DialogScanQrENVDProps) => {
    const scannerRef = React.useRef<IScannerRef | null>(null);

    useEffect(() => {
        if (!open) {
            scannerRef!.current!.closeQrCodeScanner;
        }
    }, [open]);

    /**
     * handle scan barcode success
     */
    const onScanResult = (decodedText: string) => {
        onScanQRResult(decodedText);
    };

    /**
     * handle scan barcode error
     */
    const onScanError = (error: unknown) => {
        error;
    };

    /**
     * dialogContent is used to render the content of dialog component
     */
    const dialogContent = {
        content: (
            <>
                <Scanner
                    ref={scannerRef}
                    fps={10}
                    disableFlip={false}
                    qrCodeSuccessCallback={onScanResult}
                    qrCodeErrorCallback={onScanError}
                />
            </>
        ),
    };

    /**
     * dialogStyle is used to render the style of dialog component
     */
    const dialogStyle = {
        content: {
            width: '500px',
            maxWidth: '100%',
            padding: '0px',
        },
    };

    return (
        <CustomDialog
            open={open}
            onClose={close}
            title='Scan QR'
            content={dialogContent?.content}
            style={dialogStyle}
        />
    );
};

export default DialogScanQrENVD;
