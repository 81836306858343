/**
 * @returns a random integer with number of digits specified by numberDigits
 * @example
 *  randomIntegerString(5) returns a random integer with 5 digits:
 *     12345
 *     00000
 *     00001
 */
export function randomIntegerString(numberDigits: number) {
    return Math.floor(Math.random() * 10 ** numberDigits)
        .toString()
        .padStart(numberDigits, '0');
}
