import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import { Footer } from 'react-component-utils';
import Layout from './Layout';
import Header from '../components/Header/Header';
import FallbackErrorContent from '../components/FallbackErrorContent/FallbackErrorContent';
import { ThemeProvider } from '@mui/material';
import theme from '../theme';

function App() {
    return (
        <BrowserRouter>
            <ErrorBoundary FallbackComponent={FallbackErrorContent}>
                <ThemeProvider theme={theme}>
                    <Header />
                    <Layout />
                    <Footer />
                </ThemeProvider>
            </ErrorBoundary>
        </BrowserRouter>
    );
}

export default App;
