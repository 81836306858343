export const enum ErrorText {
    required = 'is a required property',
}

export enum Status {
    success = 'success',
    error = 'error',
}

export interface IScannerRef {
    closeQrCodeScanner: () => void;
}
